.questionsSelect, .answersSelect{
    display: none;
}

@media (max-width: 885px) {
    .questionsSelect, .answersSelect{
        display: unset;
    }

    .quiestionsToggle, .answersToggle{
        display: none;
    }
}

.quiztext{
    margin-bottom:10px;
}

.quiz{

}

.bold{
    font-weight:600;
}