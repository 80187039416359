.task > table{
    width:50%;
    text-align: right;
}

.task > table td{
    padding-right:30px;
    vertical-align: top;
}

.mathresult{
    background-color: #ddd;
    width: 200px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
}

.mathresulttd{
    text-align: center;
}

.mathnumber{
    text-align: left;
}