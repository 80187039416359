body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #f4f4f4;
}

.sortable-list {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 13px 46px rgba(0, 0, 0, 0.1);
}

.input-field {
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 14px;
    font-size: 16px;
    width: 100%;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.add-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s ease;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}

.add-button:hover {
    background-color: #0056b3;
}

.sortable-list .item {
    margin-top: 1rem;
    border: 1px solid #ccc;
    justify-content: space-between;
    list-style: none;
    display: flex;
    cursor: move;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.item .details {
    display: flex;
    align-items: center;
}

.item .details img {
    height: 60px;
    width: 60px;
    pointer-events: none;
    margin-right: 15px;
    object-fit: cover;
    border-radius: 50%;
}

.item .details span {
    font-size: 1.2rem;
    font-weight: 500;
}

.item i {
    color: #474747;
    font-size: 1.13rem;
}

.item.dragging {
    opacity: 0.6;
}

.item.dragging .details,
.item.dragging i {
    opacity: 0.8;
    transform: scale(1.02);
    background: #f0f0f0;
}

.buttons{
    display: flex;
    flex-direction: row;
    gap:20px;
    font-size:1rem;
}

.list-item-enter {
    opacity: 0;
    transform: scale(0.9);
}
.list-item-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms ease, transform 300ms ease;
}
.list-item-exit {
    opacity: 1;
    transform: scale(1);
}
.list-item-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms ease, transform 300ms ease;
}

.list-group div{
    border-radius: 1rem;
}

.info-color{
    color:darkblue;
    background-color: lightblue;
}

.success-color{
    color:darkgreen;
    background-color: lightgreen;
}

.secondary-color{
    color:lightgrey;
    background-color: grey;
}

.warning-color{
    color:darkgoldenrod;
    background-color: orange;
}

.danger-color{
    color:darkred;
    background-color: red;
}

.dark-color{
    color:white;
    background-color: #333;
}

.delete-button{
    position: absolute;
right: 19px;
top: 20px;
}