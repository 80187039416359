.navbar{
    padding:10px;
    width:100%;
    background-color: rgba(255, 255, 255, 0.6) !important;
    position:fixed;
    z-index:100;
    transition: height 0.25s ease-out;
}

.navbar-big{
    height:150px;
}

.navbar-small{
    height:75px;
    background-color: rgba(255, 255, 255, 1) !important;
    border-bottom: 1px solid #ddd;
}

.container{
    max-width:100%;
    padding-left: calc(15% - 15px);
    padding-right: calc(15% - 15px);
    margin:0;
    height:100%;
}

.dropdown-menu{
    --bs-dropdown-min-width: unset;
}

.center{
    display:flex;
    justify-content: center;
    align-items: center;
}

.right{
    display:flex;
    justify-content: end;
    align-items: end;
}

.navbar-nav{
    flex-direction: row !important;
    gap:15px;
}

@media (max-width: 660px) {
    .lang {
        display:none;
    }
}

.logo{
    width:100%;
    background-size: contain;
    background-position: center;
    height: 100%;
    background-repeat: no-repeat;
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100vw);
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0%);
    }
}

.img-slide-out {
    animation: slideOut 1.5s ease-in-out;
}

.img-slide-in {
    animation: slideIn 1.5s ease-in-out;
}
