.loading-container {
    text-align: center;
    max-width: fit-content;
    color: #333;
    position: relative;
}

.loading-container:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #333;
    bottom: -10px;
    left: 0;
    border-radius: 10px;
    animation: movingLine 2.5s infinite ease-in-out;
}

@-webkit-keyframes movingLine {
    0% {
        opacity: 0;
        width: 0;
    }
    33.3%, 66% {
        opacity: 0.8;
        width: 100%;
    }
    85% {
        width: 0;
        left: initial;
        right: 0;
        opacity: 1;
    }
    100% {
        opacity: 0;
        width: 0;
    }
}

@keyframes movingLine {
    0% {
        opacity: 0;
        width: 0;
    }
    33.3%, 66% {
        opacity: 0.8;
        width: 100%;
    }
    85% {
        width: 0;
        left: initial;
        right: 0;
        opacity: 1;
    }
    100% {
        opacity: 0;
        width: 0;
    }
}

.loading-text {
    font-size: 2rem;
    letter-spacing: 10px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
}

.loading-text span {
    transform: translatex(0);
    position: relative;
    display: inline-block;
    opacity: 0;
    text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
    animation: moveLetters 2.5s infinite ease-in-out;
}

@-webkit-keyframes moveLetters {
    0% {
        transform: translateX(-15vw);
        opacity: 0;
    }
    33.3%, 66% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(15vw);
        opacity: 0;
    }
}

@keyframes moveLetters {
    0% {
        transform: translateX(-15vw);
        opacity: 0;
    }
    33.3%, 66% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(15vw);
        opacity: 0;
    }
}