.row{
    margin: 0px 10px 30px 10px;
}

.card-text{
    margin-bottom: 25px;
}

.result{
    padding: 10px;
    border: 1px solid;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
}

.showWord {
    transform: unset !important;
    clip-path: unset !important;
    color:#999 !important;
}

.word{
    background-color: rgba(255, 255, 255, 1);
    color:#555;
    display: inline-block;
}

.gap{
    border-bottom:1px solid #333;
    color: white;
}

.horizontal {
    transform: scaleX(-1);
}

.vertikal {
    transform: scaleY(-1);
}

.halbiert{
    clip-path: inset(0 0 35% 0);
}

.hidden{
    display:none;
}

.btn-group{
    width:100%;
}

.sentencesSelect, .gapsSelect{
    display: none;
}

@media (max-width: 1175px) {
    .sentencesSelect, .gapsSelect{
        display: unset;
    }

    .sentencesToggle, .gapsToggle{
        display: none;
    }
}