.horizontal {
    transform: scaleX(-1);
}

.vertikal {
    transform: scaleY(-1);
}

.halbiert{
    clip-path: inset(0 0 35% 0);
}

.pyramid{
    /*font-size: 10px;*/
}

.pyramid-left {
    text-align: left;
}

.pyramid-center {
    text-align: center;
}

.pyramid-right {
    text-align: right;
}