@font-face {
    font-family: 'Lato-Light';
    src: url("./Lato-Light.woff2") format("woff2"), url("./Lato-Light.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato-LightItalic';
    src: url("./Lato-LightItalic.woff2") format("woff2"), url("./Lato-LightItalic.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato-Regular';
    src: url("./Lato-Regular.woff2") format("woff2"), url("./Lato-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato-Italic';
    src: url("./Lato-Italic.woff2") format("woff2"), url("./Lato-Italic.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato-Semibold';
    src: url("./Lato-Semibold.woff2") format("woff2"), url("./Lato-Semibold.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato-SemiboldItalic';
    src: url("./Lato-SemiboldItalic.woff2") format("woff2"), url("./Lato-SemiboldItalic.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato-Bold';
    src: url("./Lato-Bold.woff2") format("woff2"), url("./Lato-Bold.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Lato-BoldItalic';
    src: url("./Lato-BoldItalic.woff2") format("woff2"), url("./Lato-BoldItalic.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }