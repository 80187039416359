.home{
    margin-top: -100px;
    width: calc(100% + 20px);
    margin-left: -20px;
    padding-left: 20px !important;
    padding-right: 0px !important;
}

.headerbg{
    width:100%;
    background-size: cover;
    background-position: center;
    height: 350px;
    margin-bottom:50px;
}

.white{
    background-color: white;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 40px;
    padding-top: 40px;
}

.grey{
    background-color: #f6f6f6;
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 40px;
    padding-top: 40px;
}

.overlay{
    position: absolute;
    top:175px;
    left:15%;
    display:flex;
    align-items: center;
    gap:25px;
}

.overlay img{
    width: 75px;
    height: 75px;
    transform: rotate(-5deg);
}

.overlay div{
    font-size:3rem;
    display:flex;
    align-items: center;
}