:root{
  --background-color-dark:#333;
  --background-color-light:#ccc;
  --text-color-dark:#ccc;
  --text-color-light:#333;
  --background-color-default:#333;
  --text-color-default:#ccc;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  color:var(--text-color-default);
  cursor: pointer;
}