footer{
    height: 75px;
    padding:10px 50px 10px 10px;
    width:100%;
    background: rgba(255, 255, 255);
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap:25px;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 0.75rem;
}

.links{
    display:flex;
    gap:20px;
}

.white-footer{
    background-color: white;
}

.grey-footer{
    border-top:1px solid #ddd;
}