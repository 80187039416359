.dl-app{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dl-app-row{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    gap:40px;
}