* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato-Regular';
}

body{
    min-height: 100vh;
    display: block !important;
}

.content{
    padding-top:90px;
    flex:1;
}

.content > div{
    padding-left:15%;
    padding-right:15%;
}

.row{
    align-items: stretch;
    margin: 10px 0px 30px -10px !important;
}

.card{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding:10px;
    height:100%;
}

.bodycontainer{
    display:flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
    background-color: white;
}

@keyframes slideUpFadeIn {
    0% {
        transform: translateY(25%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes toastComeIn {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0);
    }
}


.showCard {
    opacity: 0;
    animation: slideUpFadeIn .75s ease-out;
    animation-fill-mode: forwards;
}

a:hover{
    color: #1798f4 !important;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-out;
    z-index: 1000;
}

.modal-content {
    max-width: 500px;
    width: 100%;
    animation: slideUpFadeIn 0.3s ease-out;
}

.modal-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index:10;
    transition: color 0.2s ease;
}

.modal-button:hover {
    color: #1798f4;
}

.modal-fade-out{
    animation: fadeOut 0.3s ease-out;
}

.hovercard{
    cursor: pointer;
}

.hovercard:hover{
    background-color: rgba(23, 152, 244, 0.25);
    transition: background-color 0.2s ease;
}

.hovercard-selected{
    border:1px solid #1798f4;
}

.custom-toast-container{
    position: fixed;
    top:20px;
    right:20px;
    padding:20px 50px;
    border:1px solid darkgreen;
    background-color: rgba(0, 255, 0, 0.5);
    color:darkgreen;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    z-index:10000;
}

.custom-toast-come-in{
    animation: toastComeIn 0.5s ease-out;
}

.custom-toast-fade-out{
    animation: fadeOut 0.3s ease-out;
}

.card-subtitle{
    margin-bottom: 20px;
}

.maincontent-app{
    display:none;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    justify-content: center;
    align-items: center;
}

.maincontent-app .card{
    height:auto;
    padding:2rem;
}

@media (max-width: 1065px) {
    .maincontent-app {
        display: flex;
    }

    .maincontent {
        display: none;
    }
}